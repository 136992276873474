
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}
.screen {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.screen-header, footer {
  position: relative;
  color: white;
  text-align: center;

}
.middle {
  flex: 1;
  display: flex;
  flex-direction: row;

}
