.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f2f5;
  padding: 0 20px;
  height: 64px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 100;
}

.app-name {
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
}

.user-info {
  display: flex;
  align-items: center;
}


.profile-avatar {
  cursor: pointer;
}

.empty-header {
  width: 40px;
}

.ant-dropdown-menu {
  min-width: 160px;
}

.ant-dropdown-menu-item {
  display: flex;
  align-items: center;
  padding: 8px 16px;
}

.ant-dropdown-menu-item:hover {
  background-color: #f1f1f1;
}

.ant-layout-header {
  background: white;}